import React from "react"
import {PageContainer, PageWrapper, PageContent} from "../styles/GlobalStyles";
import Layout from "../components/Layout";
import {Button} from "../styles/Button"
import styled from "styled-components";
import Seo from "../components/SEO";
import ErrorImage from "../assets/svg/error-image.svg";


const ErrorPage = () => {
  return (
      <Layout>
          <Seo title="404 - Bohabo Cornhole Žaidimas" description="Bohabo Cornhole Žaidimas Lietuvoje -  Šis puslapis nerastas"/>
            <PageWrapper>
                <PageContainer>
                    <PageContent>
                        <ErrorItems>
                            <ErrorH1>Radote mūsų 404 puslapį</ErrorH1>
                            <ErrorP>Labai atsiprašome, bet puslapio, kurio ieškote, neradome. Spauskite žemiau esantį mygtuką ir sugrįšite į pagrindinį mūsų puslapį.</ErrorP>
                            <Button to="/" big="true">Į pradinį puslapį</Button>
                        </ErrorItems>
                        <ErrorImg>
                            <img src={ErrorImage} alt="Error Illustration"/>
                        </ErrorImg>
                    </PageContent>
                </PageContainer>
            </PageWrapper>
      </Layout>
  )
};

export default ErrorPage

const ErrorItems = styled.div`
    text-align: center;
    grid-column: 1/-1;
    align-self: center;
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    text-align: left;
    grid-row: 1/1;
    grid-column: 1 / span 8;
  }
`;

const ErrorImg = styled.div`
    width: 100%;
    margin: 0 auto;
    grid-row: 2/2;
    grid-column: 1/-1;
    display: flex;
    justify-content: center;

    img {
      width: 80%;
    }
      
    @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
        grid-row: 1/1;
        grid-column: 7/13;
       img {
         width: 100%;
        }
    }
 
      @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
        display: none;
      }

`;

const ErrorH1 = styled.h1`
  color: ${props => props.theme.black};
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
  font-size: clamp(32px, 6vw, 64px);
  line-height: 120%;
  margin: 0 0 2.5rem 0;
  max-width: 70%;
  letter-spacing: -1px;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 28px; 
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
    max-width: 70%;
    margin: 0 auto 1rem auto;
  }
`;

const ErrorP = styled.p`
  color: ${props => props.theme.black};
  font-size: clamp(14px, 3vw, 20px);
  max-width: 90%;
  margin: 0 auto 2.5rem;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    max-width: 65%;
    margin: 0 0 3.5rem;
  }
`;
